.cursor-move {
    cursor: move;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-zoom-in {
    cursor: zoom-in;
}